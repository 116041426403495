.reviews {

	.contain {padding-bottom: 4.5rem !important;}

	&__heading {
		margin-bottom: 3rem;
		padding-left: 4.5rem;
		position: relative;

		&::before {
			@include size(3rem);
			background: url('#{$libraryPath}/icons/src/review-quote.svg') no-repeat left top;
			content: '';
			position: absolute;
				left: 0;
				top: 0;
		}
	}

	&__slides {
		position: relative;

		// Overlay to show overflow on one side of the slider
		&::before {
			background-color: $grayLighter;
			content: '';
			height: 100%;
			position: absolute;
				top: 0;
				right: 100%;
			width: 100vw;
			z-index: 2;
		}
	}

	.flickity-viewport {
		overflow: visible;
	}

	.flickity-viewport,
	.flickity-slider,
	.flickity-enabled {
		box-shadow: none !important;
	}

	.flickity-button {
		background: $redDark;
		top: calc(100% + 1.5rem);
		transform: none;
		transition: .25s;

		&.previous {left: 0;}
		&.next {
			left: 4rem;
			right: auto;
		}

		svg path {fill: $white;}

		&:hover,
		&:focus {
			background: darken($redDark, 5%);
		}
	}

	&__slide {
		background: $white;
		display: flex;
			flex-direction: column;
		margin-right: 1.5rem;
		min-height: 100%;
		padding: 1.5rem;
		width: 90%;
	}

	&__text {
		margin-bottom: 1rem;
	}

	&__author {
		border-top: 1px solid $blue;
		margin-top: auto;
		padding-top: 1rem;
		text-align: right;

		strong {text-transform: capitalize;}
	}

	&__stars {
		display: flex;
			justify-content: center;
		margin-bottom: 1.5rem;
	}

	&__star {
		@include size(1.25rem);
		background: url('#{$libraryPath}/icons/src/star.svg') no-repeat center;
			background-size: contain;
		margin: 0 .15rem;
	}

	@include media($screen-sm) {

		&__heading {
			padding-left: 5.5rem;

			&::before {@include size(4rem);}
		}

		&__slide {
			width: 30%;
		}
	}
}