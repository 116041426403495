/**
 * Google Translate Items
 */
 .gootrans {
	display: flex;
		align-items: center;
		align-self: stretch;

	.goog-te-gadget-simple {
		background: 0;
		border: none;
		text-align: left;
		width: 100%;

		> span {
			display: inline-block;
		}

		span {
			border: none !important;
		}

		.goog-te-menu-value {
			background: 0;
			border: none;
			color: $textColor;
			font-family: $bodyFont;
			font-size: .9rem;
			margin: 0;

			span:last-child {display: none;}
		}
	}

	.google_translate_element {
		min-width: 9.5rem;
		width: 100%;
	}

	.goog-te-gadget-icon {
		display: none;
	}

	// img {display: none;}

	#translate-toggle .goog-te-gadget-icon,
	#translate-toggle-mobile .goog-te-gadget-icon {vertical-align: middle;}

	@include media($mobileNavBPMax, 'max') {
		border-bottom: 1px solid $grayLighter;
		padding: 1.25rem 1.5rem;

		#translate-toggle-mobile {padding-left: 0;}
	}

	@include media($mobileNavBP) {
		border-left: 1px solid $grayLighter;
		max-width: 17em;
		padding: 0 1rem;
	}
}

// Keep it hidden on mobile until it gets shifted into right place
@include media($mobileNavBPMax, 'max') {
	.secondary-menu + .gootrans {display: none;}

	.goog-te-menu-frame {
		top: auto !important;
		right: 0 !important;
		bottom: 0 !important;
		left: auto !important;
	}
}