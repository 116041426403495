.careers {
	background: url('#{$libraryPath}/images/blue-wave-top.svg') no-repeat top center;
		background-size: 100% auto;
	position: relative;

	&::after {
		background-color: $blueLight;
		content: '';
		height: calc(100% - 10vw);
		position: absolute;
			bottom: 0;
			left: 0;
		width: 100%;
		z-index: -1;
	}

	.contain {
		position: relative;
		z-index: 2;
	}

	&__images {
		height: 0;
		padding-bottom: 100%;
		position: relative;

		&-main {
			border-radius: 50%;
			height: 0;
			margin-left: auto;
			overflow: hidden;
			padding-bottom: 75%;
			position: absolute;
				top: 0;
				right: 0;
			width: 75%;
			z-index: 2;

			img {@include object-fit(cover, center);}
		}
	}

	&__dot {
		position: absolute;

		&-blue {
			@include size(50%);
			background: url('#{$libraryPath}/images/blue-hero-dot.svg') no-repeat center;
				background-size: contain;
			top: 4%;
			left: 9%;
		}

		&-red {
			@include size(48%);
			background: url('#{$libraryPath}/images/red-hero-dot.svg') no-repeat center;
				background-size: contain;
			left: 22%;
			bottom: 0%;
		}

		&-maroon {
			@include size(60%);
			background: $redDark;
			border-radius: 50%;
			bottom: 13%;
			left: -1rem;
		}
	}

	@include media($screen-sm) {
		padding-top: 10rem !important;

		&__images {
			padding-bottom: 40%;
			width: 40%;
		}

		&__text {
			padding-left: 4.5rem;
			width: 60%;
		}
	}
}

// When following the "getting started" layout, pull it up
@include media($screen-sm) {
	.getting-started + .careers {
		margin-top: -5rem !important;
		position: relative;
		z-index: 2;
	}
}