/**
 * Two Columns Flex Layout
 * DBS Interactive
 */

.two-columns {

	&__left,
	&__right {
		position: relative;

		img {display: block;}

		&.has-img.cover {
			border-radius: .75rem;
			overflow: hidden;
		}

		&:not(.has-img) img,
		&.has-img img {border-radius: .75rem;}
	}

	@include media($screen-sm-max, 'max') {
		&__left {margin-bottom: $spacing;}
	}

	@include media($screen-sm) {
		&__left,
		&__right {
			width: calc(50% - #{$spacing});
		
			&.contain-img { img {@include object-fit(contain, center);} }
			&.cover { img {@include object-fit(cover, center);} }
		}

		&.two-thirds-left {
			.two-columns__left {width: 60%;}
			.two-columns__right {width: 40%;}
		}
	
		&.two-thirds-right {
			.two-columns__left {width: 40%;}
			.two-columns__right {width: 60%;}
		}
	}
}