.getting-started {
	position: relative;

	&__text {

		&-item {
			font-size: .875rem;

			a {
				display: inline-block;
				margin-bottom: .5rem;
			}
		}
	}

	&__image {
		max-width: 30rem;
		z-index: 2;

		&-wrapper {
			border-radius: 50%;
			height: 0;
			overflow: hidden;
			padding-bottom: 100%;
			position: relative;

			img {@include object-fit(cover, center);}
		}
	}

	&__icon {
		height: 2.5rem;
		margin-bottom: .75rem;
		width: 3.5rem;
		position: relative;

		img {@include object-fit(contain, left center);}
	}

	@include media($screen-sm-max, 'max') {
		padding-bottom: 4.5rem;
		padding-top: 4.5rem;

		&__text {
			margin-bottom: $spacing*2;

			&-items {
				margin: -1rem;
			}

			&-item {
				padding: 1rem;
				width: 50%;

				&:nth-child(odd):not(:last-child) {
					border-right: 1px solid $gray;
				}
			}
		}

		&__image {
			margin-bottom: 3rem;
			padding-bottom: 3rem;
			position: relative;

			&::after {
				background: url('#{$libraryPath}/images/red-bg.png') no-repeat center;
				background-size: cover;
				content: '';
				height: 75%;
				position: absolute;
					bottom: 0;
					left: -$spacing;
				width: 100vw;
				z-index: -1;
			}
		}
	}

	@include media($screen-sm) {
		padding-bottom: 10rem;
		padding-top: 10rem;
		position: relative;

		&::after {
			background: url('#{$libraryPath}/images/red-bg.png') no-repeat center;
			background-size: cover;
			content: '';
			height: 100%;
			position: absolute;
				top: 0;
				left: calc(50% + 15rem);
			width: calc(50% - 15rem);
		}

		&__text {
			padding-right: $spacing*2;
			width: 50%;

			&-items {
				margin: 0 -.75rem;
			}

			&-item {
				border-top: 1px solid $gray;
				padding: 1rem;
				width: 33.3%;

				&:nth-child(2) {
					border-left: 1px solid $gray;
					border-right: 1px solid $gray;
				}
			}
		}

		&__image {
			max-width: 25rem;
			width: 50%;

			
		}
	}
}