.services {
	position: relative;

	&__background {
		@include size(100%);
		position: absolute;
			top: 0;
			bottom: 0;

		&:before {
			background: rgba($blueLight, .85);
			content: '';
			height: 100%;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			z-index: 2;
		}

		img {@include object-fit(cover, center top);}
	}

	&__content {
		h1, h2, h3, h4, h5, h6 {
			&:not(.eyebrow) {
				color: $white;
				text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.62);
			}
		}
	}

	&__strip {

		&-icon {
			@include size(3.5rem);
			margin: 0 auto .75rem;

			img {width: 100%;}
		}

		&-link {
			a {
				text-decoration: underline;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		background-image: url('#{$libraryPath}/images/home-hero-wave.svg');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: bottom;
		padding-bottom: 4.5rem;
		
		&:before {
			background: $blueLight;
			content: '';
			height: calc(100% - 10vw);
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
		}

		&__background {
			height: 50%;

			&:before {
				background: linear-gradient(to bottom, rgba($blueLight, .75), $blueLight);
				height: 101%;
			}
		}

		.contain {
			position: relative;
			z-index: 2;
		}

		&__content {
			margin-bottom: $spacing*2;
			padding-top: 3rem;
		}

		&__strip {
			margin: 0 -1rem;

			&-link {
				font-size: .875rem;
				padding: 1rem .75rem;
				position: relative;
				text-align: center;
				width: 50%;

				a {
					color: $navy;
					display: block;
				}

				&::before {
					border-bottom: 1px solid $navy;
					content: '';
					position: absolute;
						bottom: 0;
						left: 50%;
					transform: translateX(-50%);
					width: calc(100% - 1.5rem);
				}

				&:nth-child(5)::before {content: none;}

				&:nth-child(odd) {
					
					&:after {
						border-right: 1px solid $blue;
						content: '';
						height: calc(100% - 1.5rem);
						position: absolute;
							top: 50%;
							right: 0;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	@include media($screen-sm) {

		.contain {
			position: relative;
			z-index: 2;
		}

		&__background {
			border-bottom: 2rem solid white;

			&::after {
				@include size(100%);
				background: rgba($white, 0.52);
				mix-blend-mode: color;
				content: '';
				position: absolute;
					top: 0;
					left: 0;
			}
		}

		&__content {
			max-width: 26rem;
			padding: 7.5rem 0;
			width: 40%;
		}

		&__strip {
			background: white;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
			justify-content: space-between;
			margin: 0 -#{$spacing} -2rem;
			padding: $spacing;

			&-link {
				flex: 1;
				max-width: 13rem;
				padding: 0 1rem;

				&:first-child {margin-left: -1rem;}
				&:last-child {margin-right: -1rem;}

				a {color: $navy;}

				p {font-size: .875rem;}
			}

			&-icon {
				margin-left: 0;
				margin-top: -3rem;
			}
		}
	}

	@include media($screen-lg) {

		&__strip {
			padding: $spacing #{$spacing*2};
		}
	}
}