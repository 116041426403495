/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	.contain {
		max-width: $containSize;
		padding: $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	&, nav, a {font-family: $font-open-sans-condensed;}

	&__content {
		display: inline-block;
	}

	&__business {
		ul, li {
			display: block;
		}

		a {
			font-weight: 400;
		}
	}

	&__navigation {

		.footer-menu__item {
			a {
				display: block;
				font-weight: 400;
				padding: .25rem 0;

				&:after {
					background: $white;
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {color: $white; font-weight: 700; letter-spacing: 1px;}
			}
		}

		li { display: block; }
	}

	.social-media {
		margin: 1.5rem 0 1rem;

		&__link {
			display: inline-block;
				margin-right: .5rem;
		}

		a {width: 2em;}
	}

	&__credits {
		border-top: 1px solid $salmon;
		width: 100%;

		.contain {
			display: flex;
				align-items: flex-end;
				justify-content: space-between;
		}

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			a {font-weight: 400;}

			.legal-menu__item:not(:last-child) {
				border-right: 1px solid rgba($white, .2);
				margin-right: .5rem;
				padding-right: .75rem;
			}
		}
	}

	&__navigation {

		.submenu-toggle {
			display: none;
		}
	}

	@include media($screen-sm-max,'max') {
		.site-footer__credits {
			.contain {
				flex-wrap: wrap;
				text-align: center;
			}

			.legal-footer,
			.site-footer__credits-meta {width: 100%;}

			.legal-footer {margin-bottom: $spacing;}

			.legal-menu__item {margin-bottom: .75rem;}
		}
	}


	@include media($screen-md) {
		&__top.contain {padding-top: 3rem;}

		&__content {padding-right: 3rem;}

		&__navigation {
			> ul {
				display: flex;
					justify-content: space-evenly;
			}

			.footer-menu__item.toplevel:not(:last-child) {
				padding-right: $spacing*2;
			}
		}

		.social-media {margin-top: 2rem;}

		.two-columns {

			> .footer-menu__submenu {
				columns: 2;
					column-gap: 3rem;

				li {break-inside: avoid-column;}
			}
		}
	}
}
