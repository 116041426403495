/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.careers {
  background: url("/wp-content/themes/regency/library/images/blue-wave-top.svg") no-repeat top center;
  background-size: 100% auto;
  position: relative; }
  .careers::after {
    background-color: #78AEFF;
    content: '';
    height: calc(100% - 10vw);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1; }
  .careers .contain {
    position: relative;
    z-index: 2; }
  .careers__images {
    height: 0;
    padding-bottom: 100%;
    position: relative; }
    .careers__images-main {
      border-radius: 50%;
      height: 0;
      margin-left: auto;
      overflow: hidden;
      padding-bottom: 75%;
      position: absolute;
      top: 0;
      right: 0;
      width: 75%;
      z-index: 2; }
      .careers__images-main img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .careers__images-main img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
  .careers__dot {
    position: absolute; }
    .careers__dot-blue {
      height: 50%;
      width: 50%;
      background: url("/wp-content/themes/regency/library/images/blue-hero-dot.svg") no-repeat center;
      background-size: contain;
      top: 4%;
      left: 9%; }
    .careers__dot-red {
      height: 48%;
      width: 48%;
      background: url("/wp-content/themes/regency/library/images/red-hero-dot.svg") no-repeat center;
      background-size: contain;
      left: 22%;
      bottom: 0%; }
    .careers__dot-maroon {
      height: 60%;
      width: 60%;
      background: #790000;
      border-radius: 50%;
      bottom: 13%;
      left: -1rem; }
  @media screen and (min-width: 48em) {
    .careers {
      padding-top: 10rem !important; }
      .careers__images {
        padding-bottom: 40%;
        width: 40%; }
      .careers__text {
        padding-left: 4.5rem;
        width: 60%; } }

@media screen and (min-width: 48em) {
  .getting-started + .careers {
    margin-top: -5rem !important;
    position: relative;
    z-index: 2; } }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.64em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media (min-width: 48em) {
      #dbs-chev strong {
        font-size: 0.8em; } }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #000000;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  overflow: hidden;
  clear: both;
  margin-top: auto; }
  .site-footer .contain {
    max-width: 80rem;
    padding: 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    text-decoration: none; }
    .site-footer a:hover {
      text-decoration: underline; }
  .site-footer, .site-footer nav, .site-footer a {
    font-family: "Open Sans Condensed", "Helvetica", sans-serif; }
  .site-footer__content {
    display: inline-block; }
  .site-footer__business ul, .site-footer__business li {
    display: block; }
  .site-footer__business a {
    font-weight: 400; }
  .site-footer__navigation .footer-menu__item a {
    display: block;
    font-weight: 400;
    padding: .25rem 0; }
    .site-footer__navigation .footer-menu__item a:after {
      background: #ffffff;
      content: '';
      display: block;
      height: 1px;
      position: relative;
      top: 3px;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .25s ease-in-out; }
    .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
      text-decoration: none; }
      .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
        transform: scaleX(1);
        transform-origin: 0 100%; }
  .site-footer__navigation .footer-menu__item.toplevel {
    margin-bottom: 1rem; }
    .site-footer__navigation .footer-menu__item.toplevel > a {
      color: #ffffff;
      font-weight: 700;
      letter-spacing: 1px; }
  .site-footer__navigation li {
    display: block; }
  .site-footer .social-media {
    margin: 1.5rem 0 1rem; }
    .site-footer .social-media__link {
      display: inline-block;
      margin-right: .5rem; }
    .site-footer .social-media a {
      width: 2em; }
  .site-footer__credits {
    border-top: 1px solid #FF5858;
    width: 100%; }
    .site-footer__credits .contain {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .site-footer__credits .legal-footer {
      display: inline-block; }
      .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
        display: inline-block; }
      .site-footer__credits .legal-footer a {
        font-weight: 400; }
      .site-footer__credits .legal-footer .legal-menu__item:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        margin-right: .5rem;
        padding-right: .75rem; }
  .site-footer__navigation .submenu-toggle {
    display: none; }
  @media screen and (max-width: 47.999em) {
    .site-footer .site-footer__credits .contain {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      text-align: center; }
    .site-footer .site-footer__credits .legal-footer,
    .site-footer .site-footer__credits .site-footer__credits-meta {
      width: 100%; }
    .site-footer .site-footer__credits .legal-footer {
      margin-bottom: 1.5rem; }
    .site-footer .site-footer__credits .legal-menu__item {
      margin-bottom: .75rem; } }
  @media screen and (min-width: 62em) {
    .site-footer__top.contain {
      padding-top: 3rem; }
    .site-footer__content {
      padding-right: 3rem; }
    .site-footer__navigation > ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly; }
    .site-footer__navigation .footer-menu__item.toplevel:not(:last-child) {
      padding-right: 3rem; }
    .site-footer .social-media {
      margin-top: 2rem; }
    .site-footer .two-columns > .footer-menu__submenu {
      columns: 2;
      column-gap: 3rem; }
      .site-footer .two-columns > .footer-menu__submenu li {
        break-inside: avoid-column; } }

/**
 * Google Translate Items
 */
.gootrans {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch; }
  .gootrans .goog-te-gadget-simple {
    background: 0;
    border: none;
    text-align: left;
    width: 100%; }
    .gootrans .goog-te-gadget-simple > span {
      display: inline-block; }
    .gootrans .goog-te-gadget-simple span {
      border: none !important; }
    .gootrans .goog-te-gadget-simple .goog-te-menu-value {
      background: 0;
      border: none;
      color: #222A33;
      font-family: "Open Sans", "Helvetica", sans-serif;
      font-size: .9rem;
      margin: 0; }
      .gootrans .goog-te-gadget-simple .goog-te-menu-value span:last-child {
        display: none; }
  .gootrans .google_translate_element {
    min-width: 9.5rem;
    width: 100%; }
  .gootrans .goog-te-gadget-icon {
    display: none; }
  .gootrans #translate-toggle .goog-te-gadget-icon,
  .gootrans #translate-toggle-mobile .goog-te-gadget-icon {
    vertical-align: middle; }
  @media screen and (max-width: 61.999em) {
    .gootrans {
      border-bottom: 1px solid #F2F5F7;
      padding: 1.25rem 1.5rem; }
      .gootrans #translate-toggle-mobile {
        padding-left: 0; } }
  @media screen and (min-width: 62em) {
    .gootrans {
      border-left: 1px solid #F2F5F7;
      max-width: 17em;
      padding: 0 1rem; } }

@media screen and (max-width: 61.999em) {
  .secondary-menu + .gootrans {
    display: none; }
  .goog-te-menu-frame {
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: auto !important; } }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
.getting-started {
  position: relative; }
  .getting-started__text-item {
    font-size: .875rem; }
    .getting-started__text-item a {
      display: inline-block;
      margin-bottom: .5rem; }
  .getting-started__image {
    max-width: 30rem;
    z-index: 2; }
    .getting-started__image-wrapper {
      border-radius: 50%;
      height: 0;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative; }
      .getting-started__image-wrapper img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .getting-started__image-wrapper img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
  .getting-started__icon {
    height: 2.5rem;
    margin-bottom: .75rem;
    width: 3.5rem;
    position: relative; }
    .getting-started__icon img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .getting-started__icon img {
          height: 100%;
          object-fit: contain;
          object-position: left center; } }
  @media screen and (max-width: 47.999em) {
    .getting-started {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; }
      .getting-started__text {
        margin-bottom: 3rem; }
        .getting-started__text-items {
          margin: -1rem; }
        .getting-started__text-item {
          padding: 1rem;
          width: 50%; }
          .getting-started__text-item:nth-child(odd):not(:last-child) {
            border-right: 1px solid #ECEDF0; }
      .getting-started__image {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        position: relative; }
        .getting-started__image::after {
          background: url("/wp-content/themes/regency/library/images/red-bg.png") no-repeat center;
          background-size: cover;
          content: '';
          height: 75%;
          position: absolute;
          bottom: 0;
          left: -1.5rem;
          width: 100vw;
          z-index: -1; } }
  @media screen and (min-width: 48em) {
    .getting-started {
      padding-bottom: 10rem;
      padding-top: 10rem;
      position: relative; }
      .getting-started::after {
        background: url("/wp-content/themes/regency/library/images/red-bg.png") no-repeat center;
        background-size: cover;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(50% + 15rem);
        width: calc(50% - 15rem); }
      .getting-started__text {
        padding-right: 3rem;
        width: 50%; }
        .getting-started__text-items {
          margin: 0 -.75rem; }
        .getting-started__text-item {
          border-top: 1px solid #ECEDF0;
          padding: 1rem;
          width: 33.3%; }
          .getting-started__text-item:nth-child(2) {
            border-left: 1px solid #ECEDF0;
            border-right: 1px solid #ECEDF0; }
      .getting-started__image {
        max-width: 25rem;
        width: 50%; } }

.locations-layout__map img {
  transform: scale(1.1);
  width: 100%; }

.locations-layout__text {
  margin-bottom: 3rem; }

@media screen and (min-width: 48em) {
  .locations-layout__text, .locations-layout__map {
    width: 50%; }
  .locations-layout__text {
    padding-left: 3rem; } }

@media screen and (min-width: 48em) and (max-width: 61.999em) {
  .locations-layout .location-form__form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
    .locations-layout .location-form__form .location-form__field {
      margin-bottom: .75rem;
      width: 100%; }
    .locations-layout .location-form__form .button {
      margin-left: 0; } }

@media screen and (min-width: 75em) {
  .locations-layout .layout:not(.flex-collapse) .location-form__field {
    width: 20rem; } }

.reviews .contain {
  padding-bottom: 4.5rem !important; }

.reviews__heading {
  margin-bottom: 3rem;
  padding-left: 4.5rem;
  position: relative; }
  .reviews__heading::before {
    height: 3rem;
    width: 3rem;
    background: url("/wp-content/themes/regency/library/icons/src/review-quote.svg") no-repeat left top;
    content: '';
    position: absolute;
    left: 0;
    top: 0; }

.reviews__slides {
  position: relative; }
  .reviews__slides::before {
    background-color: #F2F5F7;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    z-index: 2; }

.reviews .flickity-viewport {
  overflow: visible; }

.reviews .flickity-viewport,
.reviews .flickity-slider,
.reviews .flickity-enabled {
  box-shadow: none !important; }

.reviews .flickity-button {
  background: #790000;
  top: calc(100% + 1.5rem);
  transform: none;
  transition: .25s; }
  .reviews .flickity-button.previous {
    left: 0; }
  .reviews .flickity-button.next {
    left: 4rem;
    right: auto; }
  .reviews .flickity-button svg path {
    fill: #ffffff; }
  .reviews .flickity-button:hover, .reviews .flickity-button:focus {
    background: #600000; }

.reviews__slide {
  background: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 1.5rem;
  min-height: 100%;
  padding: 1.5rem;
  width: 90%; }

.reviews__text {
  margin-bottom: 1rem; }

.reviews__author {
  border-top: 1px solid #4E77B9;
  margin-top: auto;
  padding-top: 1rem;
  text-align: right; }
  .reviews__author strong {
    text-transform: capitalize; }

.reviews__stars {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 1.5rem; }

.reviews__star {
  height: 1.25rem;
  width: 1.25rem;
  background: url("/wp-content/themes/regency/library/icons/src/star.svg") no-repeat center;
  background-size: contain;
  margin: 0 .15rem; }

@media screen and (min-width: 48em) {
  .reviews__heading {
    padding-left: 5.5rem; }
    .reviews__heading::before {
      height: 4rem;
      width: 4rem; }
  .reviews__slide {
    width: 30%; } }

.services {
  position: relative; }
  .services__background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0; }
    .services__background:before {
      background: rgba(120, 174, 255, 0.85);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2; }
    .services__background img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .services__background img {
          height: 100%;
          object-fit: cover;
          object-position: center top; } }
  .services__content h1:not(.eyebrow), .services__content h2:not(.eyebrow), .services__content h3:not(.eyebrow), .services__content h4:not(.eyebrow), .services__content h5:not(.eyebrow), .services__content h6:not(.eyebrow) {
    color: #ffffff;
    text-shadow: 0px 2px 9px rgba(0, 0, 0, 0.62); }
  .services__strip-icon {
    height: 3.5rem;
    width: 3.5rem;
    margin: 0 auto .75rem; }
    .services__strip-icon img {
      width: 100%; }
  .services__strip-link a {
    text-decoration: underline; }
    .services__strip-link a:hover, .services__strip-link a:focus {
      text-decoration: none; }
  @media screen and (max-width: 47.999em) {
    .services {
      background-image: url("/wp-content/themes/regency/library/images/home-hero-wave.svg");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: bottom;
      padding-bottom: 4.5rem; }
      .services:before {
        background: #78AEFF;
        content: '';
        height: calc(100% - 10vw);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; }
      .services__background {
        height: 50%; }
        .services__background:before {
          background: linear-gradient(to bottom, rgba(120, 174, 255, 0.75), #78AEFF);
          height: 101%; }
      .services .contain {
        position: relative;
        z-index: 2; }
      .services__content {
        margin-bottom: 3rem;
        padding-top: 3rem; }
      .services__strip {
        margin: 0 -1rem; }
        .services__strip-link {
          font-size: .875rem;
          padding: 1rem .75rem;
          position: relative;
          text-align: center;
          width: 50%; }
          .services__strip-link a {
            color: #1A2E50;
            display: block; }
          .services__strip-link::before {
            border-bottom: 1px solid #1A2E50;
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 1.5rem); }
          .services__strip-link:nth-child(5)::before {
            content: none; }
          .services__strip-link:nth-child(odd):after {
            border-right: 1px solid #4E77B9;
            content: '';
            height: calc(100% - 1.5rem);
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%); } }
  @media screen and (min-width: 48em) {
    .services .contain {
      position: relative;
      z-index: 2; }
    .services__background {
      border-bottom: 2rem solid white; }
      .services__background::after {
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.52);
        mix-blend-mode: color;
        content: '';
        position: absolute;
        top: 0;
        left: 0; }
    .services__content {
      max-width: 26rem;
      padding: 7.5rem 0;
      width: 40%; }
    .services__strip {
      background: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0 -1.5rem -2rem;
      padding: 1.5rem; }
      .services__strip-link {
        -ms-flex: 1;
        flex: 1;
        max-width: 13rem;
        padding: 0 1rem; }
        .services__strip-link:first-child {
          margin-left: -1rem; }
        .services__strip-link:last-child {
          margin-right: -1rem; }
        .services__strip-link a {
          color: #1A2E50; }
        .services__strip-link p {
          font-size: .875rem; }
      .services__strip-icon {
        margin-left: 0;
        margin-top: -3rem; } }
  @media screen and (min-width: 75em) {
    .services__strip {
      padding: 1.5rem 3rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  position: relative; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img.cover, .two-columns__right.has-img.cover {
    border-radius: .75rem;
    overflow: hidden; }
  .two-columns__left:not(.has-img) img,
  .two-columns__left.has-img img, .two-columns__right:not(.has-img) img,
  .two-columns__right.has-img img {
    border-radius: .75rem; }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 1.5rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .two-columns.two-thirds-left .two-columns__left {
    width: 60%; }
  .two-columns.two-thirds-left .two-columns__right {
    width: 40%; }
  .two-columns.two-thirds-right .two-columns__left {
    width: 40%; }
  .two-columns.two-thirds-right .two-columns__right {
    width: 60%; } }
