.locations-layout {

	&__map {

		img {
			transform: scale(1.1);
			width: 100%;
		}
	}

	&__text {
		margin-bottom: 3rem;
	}

	@include media($screen-sm) {

		&__text,
		&__map {
			width: 50%;
		}

		&__text {
			padding-left: 3rem;
		}

		@include media($screen-md-max, 'max') {

			.location-form__form {
				display: flex;flex-direction: column;

				.location-form__field {
					margin-bottom: .75rem;
					width: 100%;
				}

				.button {
					margin-left: 0;
				}
			}
		}
	}

	@include media($screen-lg) {

		.layout:not(.flex-collapse) .location-form__field {
			width: 20rem;
		}
	}
}